import React from "react";
import { Link } from "gatsby";
import "./Button.scss";

const Button = (props) => {
  return (
    <Link
      to={props.href}
      data-color={props.dataColor}
      data-form={props.dataForm}
      className="btn"
    >
      {props.children}
    </Link>
  );
};

export default Button;
