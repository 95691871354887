import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer>
      <p>DLSIM | このサイトはデモサイトです</p>
      <p>© Demand Link.Co.,Ltd.</p>
    </footer>
  );
};

export default Footer;
