import React from "react";
import { Helmet } from "react-helmet";
import Icon from "../images/favicon.ico";

const Head = (props) => {
  return (
    <Helmet>
      <html lang="ja" />
      <title>{props.title}</title>

      <link rel="icon" type="image/png" href={Icon} />
    </Helmet>
  );
};

export default Head;
